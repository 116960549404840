const initialState = {
  "id": 8,
  "thingName": "Avocado",
  "resourceUrl": "thenight.png",
  "defaultIndex": 1
};


export default function (state = initialState, action) {
  switch (action.type) {
    case 'THING_SELECTED':
      return action.payload
    default:
      return state
  }
}

