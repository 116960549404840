import React from 'react';
import { useDispatch, useSelector  } from 'react-redux'
import CoverPreview from "./CoverPreview";
import selectThing from '../../actions/action_select_thing'

const Things = () => {
  const things = useSelector(state => state.things);
  const activeTheme = useSelector(state => state.activeTheme);
  const activeThing = useSelector(state => state.activeThing);
  const dispatch = useDispatch();

  return(
    <section className="c-smallThingsBoxGrid">
      {things.map((thing) =>(
        <CoverPreview
          key={thing.id}
          active={activeThing.id === thing.id}
          onPress={() => dispatch(selectThing(thing))}
          object={{...thing}}
          theme={activeTheme}
          containerClass="c-smallThingsBox"
        />
      ))}
    </section>  
  )
}

export default Things;