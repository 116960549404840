import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ProgressiveImage from 'react-progressive-image';

const CoverPreview = props => {
  const {
    onPress= () => {},
    theme = {},
    object = {},
    active = false,
    containerClass="",
  } = props;

  const {
    contrastColor,
    textColor,
    backgroundColor,
  } = theme;

  const {
    resourceUrl,
    thingName,
  } = object;

  const objectUrl = `/images/objects/${resourceUrl}`;
  const objectPlaceholderUrl = `/images/objects/placeholders/${resourceUrl}`;

  return(
    <div
      className={`${containerClass} ${active ? 'active':''}`}
      style={{ background: backgroundColor }}
      onClick={onPress}
      onKeyPress={onPress}
      tabIndex="0"
      role="button"
      aria-label="Select theme or thing"
    >
      <span
        className="c-smallPreviewBox--contrast c-smallPreviewBox--contrastLine1"
        style={{background: contrastColor}}
      />
      <span
        className="c-smallPreviewBox--contrast c-smallPreviewBox--contrastLine2" 
        style={{background: contrastColor}}
      />
      <LazyLoadComponent>
        <ProgressiveImage
          src={objectUrl}
          placeholder={objectPlaceholderUrl}
        >
          {(src, loading) => (
            <img 
              className={`${containerClass}--image ${  loading ? 'loading' : ''}`} 
              src={src} 
              alt={thingName}
            />
          )}
        </ProgressiveImage>
      </LazyLoadComponent>
      <span
        className="c-smallPreviewBox--text c-smallPreviewBox--textLine1"
        style={{background: textColor}}
      />
      <span
        className="c-smallPreviewBox--text c-smallPreviewBox--textLine2"
        style={{background: textColor}}
      />
    </div>
  )
}

export default CoverPreview;

CoverPreview.propTypes={
  onPress: PropTypes.func,
  theme: PropTypes.shape({
    id: PropTypes.number,
    themeName: PropTypes.string,
    contrastColor: PropTypes.string,
    textColor:PropTypes.string,
    backgroundColor:PropTypes.string,
  }),
  object: PropTypes.shape({
    id: PropTypes.number,
    thingName: PropTypes.string,
    resourceUrl: PropTypes.string,
    defaultIndex: PropTypes.number,
  }),
  active: PropTypes.bool,
  containerClass: PropTypes.string,
}