import React from 'react';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux'

import CanvasEditor from '../Canvas/CanvasEditor'

const Aside = () => {
  const activeTheme = useSelector(state => state.activeTheme);
  return(
    <div className="s-aside">
      <div className="c-aside-container">
        <a
          className="c-aside-logo"
          href="/"
          title="Replace Your Spotify Cover Art"
        >
          <SVG src="./svg/logo.svg" />
        </a>
        <div className="c-aside-content">
          <h1 style={{  color: activeTheme.backgroundColor   }}>
            Create cool album art <br />for your Spotify playlists
          </h1>
        </div>

        <div className="c-aside-actions">
          <a href="/about" title="About this thing" className="inline-block">
            <span role="img" aria-label="finger">👉</span> About &amp; Roadmap 
          </a>
          <br />
          <a href="/changelog" title="What's new" className="inline-block mt-2">
            <span role="img" aria-label="finger">👉</span> Changelog
          </a>
        </div>
        <CanvasEditor />
      </div>
    </div>
  )
}

export default Aside;