import React from 'react';
import CanvasPreview from './CanvasPreview';

const Canvas = () => {
  return (
    <div className="s-canvas">
      <div className="s-convasContainer">
        <CanvasPreview />
      </div>
    </div>
  )
}

export default Canvas;