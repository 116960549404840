export default function () {
  return [
    {
      "id": 1,
      "author": "This Is",
      "title": "Sensitive Guys From The 70's",

    },
    {
      "id": 2,
      "author": "This Is",
      "title": "Ultimate Guilty Pleasures",
    },
    {
      "id": 3,
      "author": "This Is",
      "title": "Hipster's Guide To Mornings",
    },
    {
      "id": 4,
      "author": "This Is",
      "title": "Grunts, Groans & Moans",
    },
    {
      "id": 5,
      "author": "This Is",
      "title": "That's What She Said",
    },
    {
      "id": 6,
      "author": "This Is",
      "title": "New Dogs, Old Tricks",
    },
    {
      "id": 7,
      "author": "This Is",
      "title": "One-hit Wonders",
    },
    {
      "id": 8,
      "author": "This Is",
      "title": "Songs About Songs",
    },
    {
      "id": 9,
      "author": "This Is",
      "title": "Mob Hits",
    },
    {
      "id": 10,
      "author": "This Is",
      "title": "Can Of Pop",
    },
    {
      "id": 11,
      "author": "This Is",
      "title": "This is what Happens when you grow up watching videos on MTV",
    },
    {
      "id": 12,
      "author": "This Is",
      "title": "Baby Making Music",
    },
    {
      "id": 13,
      "author": "This Is",
      "title": "Crybabies R Us",
    },
    {
      "id": 14,
      "author": "This Is",
      "title": "People That Actually Play Instruments",
    },
    {
      "id": 15,
      "author": "This Is",
      "title": "Clubbed to Death",
    },
    {
      "id": 16,
      "author": "This Is",
      "title": "Songs To Wear Pants To",
    },
    {
      "id": 17,
      "author": "This Is",
      "title": "Forever Alone",
    },
    {
      "id": 18,
      "author": "This Is",
      "title": "Kitchen Gangsta",
    },
    {
      "id": 19,
      "author": "This Is",
      "title": "Depressed Pizzas",
    },
    {
      "id": 20,
      "author": "This Is",
      "title": "Sunshine and Cocktails",
    },
    {
      "id": 21,
      "author": "This Is",
      "title": "Music From the Future",
    },
    {
      "id": 22,
      "author": "This Is",
      "title": "I Am Not Your Favorite Rapper.",
    },
    {
      "id": 23,
      "author": "This Is",
      "title": "My Neighbours Love Me",
    },
    {
      "id": 24,
      "author": "This Is",
      "title": "Engage The Machine",
    },
    {
      "id": 25,
      "author": "This Is",
      "title": "Flying Candy Basket",
    },
    {
      "id": 26,
      "author": "This Is",
      "title": "Never Gonna Give You Up",
    },
    {
      "id": 27,
      "author": "This Is",
      "title": "Quiet! This is a library!",
    },
    {
      "id": 28,
      "author": "This Is",
      "title": "Zzzzzzzzzzzzzz...",
    },
    {
      "id": 29,
      "author": "This Is",
      "title": "Uneasy Listening",
    },
    {
      "id": 30,
      "author": "This Is",
      "title": "Prescription Beats",
    },
    {
      "id": 31,
      "author": "This Is",
      "title": "Divas, Dames & Sophisticated Ladies",
    },
    {
      "id": 32,
      "author": "This Is",
      "title": "Catch & Release",
    },
    {
      "id": 33,
      "author": "This Is",
      "title": "Plaid Shirt Days",
    },
    {
      "id": 34,
      "author": "This Is",
      "title": "Training Day",
    },
    {
      "id": 35,
      "author": "This Is",
      "title": "Discover Daily",
    }
  ];
}