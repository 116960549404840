const initialState = {
 
  "id": 3,
  "themeName": "Blue + Yellow",
  "backgroundColor": "#4CFC4F",
  "textColor": "#FFFFFF",
  "contrastColor": "#B646FF"

};


export default function(state = initialState, action) {
  switch (action.type) {
    case 'THEME_SELECTED':
      return action.payload;
    default: 
      return state
  } 
}


