import React from 'react';
import ReactGA from 'react-ga';

const About = () => {

  const trackPageView = (
    ReactGA.pageview('/about')
  );

  return (
    <div className="p-about">
      <div className="s-about">
        <div className="c-about-intro">
          <a href="/"><span role="img" aria-label="back">👈</span> go back</a>
          <h1> Automatically generated playlist covers are boring. </h1>
          <p>
            I’m Radu, a product designer and co-founder of this <a href="http://perfect10.io" title="Perfect 10 Studio">digital product studio</a> and this <a href="https://specstimate.com" title="Specstimate - Estimate Better Together">SaaS product for digital agencies</a>. Oh and I’m addicted to Spotify.
          </p>

          <p>
            If you like this thing, share it with your friends. Or keep it a secret. Maybe tweet about it and use <em>#replacecover</em>? Why not listen to <a href="https://open.spotify.com/user/raduceuca" title="Radu on Spotify" target="_blank" rel="noopener noreferrer">my playlists on Spotify</a>? I dunno. You do you.
          </p>

          <p>
            <span role="img" aria-label="hello">👋</span> <a href="https://twitter.com/raduceuca" title="Radu's on the twitterz">@raduceuca</a> on The Twitter.
            <br />
            <span role="img" aria-label="email">💌</span> <a href="mailto:raduceuca@gmail.com?subject=Replace Cover Ideas" title="Email Radu">raduceuca@gmail.com</a>
          </p>

          <p>
            <span role="img" aria-label="kudos">🙏</span> to <a href="https://twitter.com/alexasferle" title="Alexa" target="_blank" rel="noopener noreferrer">Alexa</a>, <a href="https://chezcami.com" title="Chez Cami" target="_blank" rel="noopener noreferrer">Cami</a>, <a href="http://freelancelot.ro/" title="Vlad" target="_blank" rel="noopener noreferrer">Vlad</a>, <a href="https://twitter.com/adelasabou" title="Saboutage" target="_blank" rel="noopener noreferrer">Adela</a> &amp; <a href="http://www.dora.io/client/" title="Dora Szabo">Dora</a> for testing this.
          </p>
        </div>

        <div className="c-about-questions">
          <h2>In case you were wondering...</h2>
          <ul className="c-about-questions-list">
            <li>
              <p className="c-about-questions-list--q">How do I replace my Spotify playlist cover art?</p>
              <p className="c-about-questions-list--a"><strong>Spoiler Alert: this only works in the Spotify desktop player.</strong><br /><br />Open one of your playlists, hover your mouse over the boring cover art and clickety click it. <br /><br />You&apos;ll see the &quot;Edit Playlist&quot; screen. Clickety click again on the &quot;Choose Image&quot; button and select the new image.</p>
            </li>
            <li>
              <p className="c-about-questions-list--q">Does this only work with Spotify?</p>
              <p className="c-about-questions-list--a">Of course not. You can also change your playlist cover art on Apple Music, Soundcloud and pretty much every other music streaming service out there.</p>
            </li>
            <li>
              <p className="c-about-questions-list--q">How did you built this?</p>
              <p className="c-about-questions-list--a">This thing right here is a <a href="https://reactjs.org/" title="React" target="_blank" rel="noopener noreferrer">React</a> app, most of the images are from <a href="http://pngimg.com/" title="pngimg" target="_blank" rel="noopener noreferrer">pngimg</a> or <a href="https://unsplash.com/" title="Unsplash" target="_blank" rel="noopener noreferrer">Unsplash</a> (cheers!). Maybe there&apos;ll be a Medium post about this. At some point. If anybody&apos;s interested in how a designer learned React and built this in a week.</p>
            </li>
            <li>
              <p className="c-about-questions-list--q">Should designers code?</p>
              <p className="c-about-questions-list--a">Well...</p>
            </li>
          </ul>
        </div>
        <div className="c-about-roadmap">
          <h2>Roadmap</h2>
          <p className="c-about-roadmap--description">This is the first version of Replace Cover. Designed, built and shipped in about a week, working weekends and nights. Because of the overwhelmingly positive response so far, I&apos;m working on a few updates:</p>
          <ul className="c-about-roadmap-list">
            <li>
              <p className="c-about-roadmap-list--feature">Integration of Unsplash</p>
              <p className="c-about-roadmap-list--featureDescription">Integration of <a href="https://unsplash.com" title="Unsplash is awesome">Unsplash</a> and their 470k+ library of free amazing photos. You&apos;ll be able to select one, tweak it a little bit and use it as your playlist cover background.
                <span className="c-about-roadmap-list-status">
                  Status:
                  <strong className="c-about-roadmap-list--featureStatus indesign">
                    In Design
                  </strong>
                </span>
              </p>
            </li>
            <li>
              <p className="c-about-roadmap-list--feature indesign">
                Control over the vertical position of the playlist title
              </p>
              <p className="c-about-roadmap-list--featureDescription">
                Moving the playlist title up &amp; down by dragging it, rather than adding line breaks before and after the text.
                <span className="c-about-roadmap-list-status">
                  Status:
                  <strong
                    className="c-about-roadmap-list--featureStatus wip"
                  >
                    In Development
                  </strong>
                </span>
              </p>
            </li>

            <li>
              <p className="c-about-roadmap-list--feature">
                Connecting your Spotify account
              </p>
              <p className="c-about-roadmap-list--featureDescription">
                Ability to log in with your Spotify account, select one of your awesome playlists, design the cover and then save it directly to Spotify, without having to leave Replace Cover.
                <span className="c-about-roadmap-list-status">
                  Status:
                  <strong
                    className="c-about-roadmap-list--featureStatus indesign"
                  >
                    In Design
                  </strong>
                </span>
              </p>
            </li>

            <li>
              <p className="c-about-roadmap-list--feature">
                What else?
              </p>
              <p className="c-about-roadmap-list--featureDescription">
                Let me know what else you&apos;d like to see Replace Cover do either on Twitter <a href="https://twitter.com/raduceuca" title="Radu on Twitter">@raduceuca</a> or via email <a href="mailto:raduceuca@gmail.com?subject=Replace Cover Ideas" title="Email Radu">raduceuca@gmail.com</a>
              </p>
            </li>
          </ul>
        </div>
      </div>
      {trackPageView}
    </div>
  )
}

export default About;