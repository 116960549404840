// src/js/reducers/index.js
import { combineReducers } from 'redux'
import themesReducer from './reducer_themes'
import thingsReducer from './reducer_things'
import activeThemeReducer from './reducer_active_theme'
import activeThingReducer from './reducer_active_thing'
import activeCopyReducer from './reducer_active_copy'

import textSizeReducer from './Text/reducer_text_size'
import textAlignmentReducer from './Text/reducer_text_alignment'

import playlistNamesReducer from './reducer_playlist_names'

const rootReducer = combineReducers({
  themes: themesReducer,
  things: thingsReducer,
  activeTheme: activeThemeReducer,
  activeThing: activeThingReducer,
  activeCopy: activeCopyReducer,
  textSize: textSizeReducer,
  textAlignment: textAlignmentReducer,
  randomPlaylistNames: playlistNamesReducer
});

export default rootReducer;