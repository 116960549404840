import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'

import changeTextAlignment from '../../../actions/action_change_text_alignment'

const alignmentOptions = ['left', 'center', 'right'];

const TextAlignment = () => {
  const textAlignment = useSelector(state => state.textAlignment);
  const dispatch = useDispatch();

  return(
    <div className="c-tools-section c-tools-TextAlignment">
      {alignmentOptions.map(option => (
        <AlignButton
          key={`button-${option}`}
          direction={option}
          active={textAlignment.alignment === option}
          onPress={() => dispatch(changeTextAlignment({alignment:option}))}
        /> 
      ))}
    </div>
  );
}

export default TextAlignment;

const AlignButton = props => {
  const {
    direction = 'left',
    active = false,
    onPress = () => {},
  } = props;
      
  return(
    <div
      className={`c-tools-section--action ${  active? 'active' : ''}`}
      onClick={onPress}
      role="button"
      tabIndex="0"
      onKeyPress={onPress}
      aria-label={`Align text to ${direction}`}
    >
      <span className={`ic ic-align-${direction} ic-align-${direction}-dims`} />
    </div>
  )
}

AlignButton.propTypes = {
  direction:PropTypes.string,
  active: PropTypes.bool,
  onPress: PropTypes.func,
}