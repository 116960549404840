import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

import changeTextSize from '../../../actions/action_change_text_size'

const TextSize = () => {
  const textSize = useSelector(state => state.textSize);
  const dispatch = useDispatch();
  const incrementStep = 4;

  function increment(size) {
    dispatch(
      changeTextSize(
        {size}
      )
    );
  };

  return(
    <div className="c-tools-section c-tools-TextSize">
      <div
        className="c-tools-section--action"
        onClick={() => increment(textSize.size-incrementStep)}
        onKeyPress={() => increment(textSize.size-incrementStep)}
        tabIndex="0"
        role="button"
        aria-label="Decrease text size"
      >
        <span className="ic ic-size-decrease ic-size-dims" />
      </div>
      <div className="c-tools-section--label">
        <span className="ic ic-size ic-size-dims" />
      </div>
      <div
        className="c-tools-section--action"
        onClick={() => increment(textSize.size+incrementStep)}
        onKeyPress={() => increment(textSize.size+incrementStep)}
        tabIndex="0"
        role="button"
        aria-label="Increase text size"
      >
        <span className="ic ic-size-increase ic-size-increase-dims" />
      </div>
    </div> 
  )
}
export default TextSize;