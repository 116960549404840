import React from 'react';
// import EditorNavigation from './EditorNavigation';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Themes from './Themes';
import Things from './Things';

const Editor = () => {

  return (
    <div className="s-editor">  
      <div className="s-editorContainer">   
        <Tabs>
          <TabList>
            <Tab><span className="text-gray-200">Things</span></Tab>
            <Tab><span className="text-gray-200">Themes</span></Tab>
          </TabList>
          <TabPanel>
            <Things />
          </TabPanel>
          <TabPanel>
            <Themes />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Editor;