import React from 'react';
import Shuffle from './Tools/Shuffle'
import TextSize from './Tools/TextSize'
import TextAlignment from './Tools/TextAlignment'
// import ObjectRotation from './Tools/ObjectRotation'
// import ObjectFlip from './Tools/ObjectFlip'
import Download from './Tools/Download';

const CanvasEditor = () => {
  return (
    <div className="c-canvasEditor">
      <Shuffle />
      <TextSize />
      <TextAlignment />
      <Download />
    </div>
  )
}

export default CanvasEditor;