import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

// TODO: remove
import ReactGA from 'react-ga';
import changeCopy from '../../../actions/action_change_copy'
import selectTheme from '../../../actions/action_select_theme'
import selectThing from '../../../actions/action_select_thing'

const rand = (max) => {
  return  Math.floor(Math.random() * (max - 1))
}

const Shuffle = () => {
  const themes = useSelector(state => state.themes);
  const things = useSelector(state => state.things);
  const randomPlaylistNames = useSelector(state => state.randomPlaylistNames);
  const dispatch = useDispatch();

  function randomizeCover() {
    dispatch(changeCopy({
      playlistAuthor: randomPlaylistNames[rand(randomPlaylistNames.length -1 )].author,
      playlistTitle: randomPlaylistNames[rand(randomPlaylistNames.length -1)].title,
    }));

    dispatch(selectTheme(themes[rand(themes.length)]));
    dispatch(selectThing(things[rand(things.length)]));

    ReactGA.event({
      category: 'Covers',
      action: 'Shuffled'
    });
  }

  return(
    <div className="c-tools-section c-tools-Shuffle">
      <div 
        className="c-tools-section--action"
        onClick={() => randomizeCover()}
        onKeyPress={() => randomizeCover()}
        tabIndex="0"
        role="button"
        aria-label="Shuffle Cover Design"
      >
        <span className="ic ic-shuffle ic-shuffle-dims" />
      </div>
    </div>
  );

}

export default Shuffle;