const initialState = {
  "size": 60
};


export default function (state = initialState, action) {
  switch (action.type) {
    case 'TEXT_SIZE_CHANGED':
      return action.payload;
    default:
      return state
  }
}

