import React, {useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect';
import Textarea from "react-textarea-autosize";

import ProgressiveImage from 'react-progressive-image';
import changeCopy from '../../actions/action_change_copy'


const CanvasPreview = () => {
  const activeTheme = useSelector(state => state.activeTheme);
  const activeCopy = useSelector(state => state.activeCopy);
  const activeThing = useSelector(state => state.activeThing);
  const textSize = useSelector(state => state.textSize);
  const textAlignment = useSelector(state => state.textAlignment);
  const dispatch = useDispatch();

  const objectUrl = `/images/objects/${activeThing.resourceUrl}`;
  const objectPlaceholderUrl = `/images/objects/placeholders/${activeThing.resourceUrl}`;

  const responsiveFontSize = isMobile ? `${textSize.size*0.5}px` : `${textSize.size}px`;

  const titleRef = useRef(null);
  const authorRef = useRef(null);
  
  function handleAuthorChange(e) {
    dispatch(changeCopy({ 
      playlistAuthor: e.target.value,
      playlistTitle:activeCopy.playlistTitle,
    }));

  };

  function handleTitleChange(e) {
    dispatch(changeCopy({ 
      playlistAuthor:activeCopy.playlistAuthor,
      playlistTitle: e.target.value,
    }));
  };

  return(
    <div className="c-canvasPreview">
      <div
        className="c-canvasPreviewBox"
        id="canvasPreview"
        style={{
          backgroundColor: activeTheme.backgroundColor,
          backgroundSize: '100%',
          backgroundPosition: 'top left',
        }}
      >
        <ProgressiveImage
          src={objectUrl}
          placeholder={objectPlaceholderUrl}
        >
          {(src, loading) => (
            <img 
              className={`c-canvasPreviewBox--image ${  loading && 'loading'}`} 
              src={src} 
              alt=""
            />
          )}
        </ProgressiveImage>

        <div className="c-canvasPreviewBox--container">
          <input
            value={activeCopy.playlistAuthor} // innerHTML of the editable div
            onChange={handleAuthorChange} // handle innerHTML change
            ref={authorRef}
            className="c-canvasPreview--author textareaReset"
            style={{
              color: activeTheme.contrastColor,
              fontSize: activeCopy.playlistAuthorFontSize,
              textAlign: textAlignment.alignment
            }}
            spellCheck="false"
          />

          <div className="c-canvasPreview--title">
            <Textarea
              autoFocus
              cacheMeasurements
              value={activeCopy.playlistTitle} // innerHTML of the editable div
              onChange={handleTitleChange} // handle innerHTML change
              ref={titleRef} // handle innerHTML change
              className="c-canvasPreview--textarea textareaReset"
              style={{
                color: activeTheme.textColor,
                fontSize: responsiveFontSize,
                textAlign: textAlignment.alignment
              }}
              spellCheck="false"
              maxRows={10}
              minRows={1}
            />
          </div>

          <div className="c-canvasPreview--title-screenshot">
            <p
              className="c-canvasPreview--textarea textareaReset"
              style={{
                color: activeTheme.textColor,
                fontSize: responsiveFontSize,
                textAlign: textAlignment.alignment
              }}
            >{activeCopy.playlistTitle}
            </p>
          </div>

          <span 
            className={`c-canvasPreview--pill ${textAlignment.alignment}`}
            style={{
              background: activeTheme.contrastColor
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CanvasPreview;