export default function () {
  return [
    {
      "id": 1,
      "thingName": "Avocado",
      "resourceUrl": "avocado.png",
      "defaultIndex": 1
    },
    {
      "id": 2,
      "thingName": "Baloon",
      "resourceUrl": "baloon.png",
      "defaultIndex": 0
    },
    {
      "id": 3,
      "thingName": "Glasses",
      "resourceUrl": "glasses.png",
      "defaultIndex": 0
    },
    {
      "id": 4,
      "thingName": "intentions",
      "resourceUrl": "intentions.png",
      "defaultIndex": 0
    },
    {
      "id": 5,
      "thingName": "lips",
      "resourceUrl": "lips.png",
      "defaultIndex": 0
    },
    {
      "id": 6,
      "thingName": "mask",
      "resourceUrl": "mask.png",
      "defaultIndex": 0
    },
    {
      "id": 7,
      "thingName": "sould",
      "resourceUrl": "sould.png",
      "defaultIndex": 0
    },
    {
      "id": 8,
      "thingName": "thenight",
      "resourceUrl": "thenight.png",
      "defaultIndex": 0
    },
    {
      "id": 9,
      "thingName": "tropical",
      "resourceUrl": "tropical.png",
      "defaultIndex": 0
    },
    {
      "id": 10,
      "thingName": "turkish",
      "resourceUrl": "turkish.png",
      "defaultIndex": 0
    },
    {
      "id": 11,
      "thingName": "yesyoucan",
      "resourceUrl": "yesyoucan.png",
      "defaultIndex": 0
    },
    {
      "id": 12,
      "thingName": "converse",
      "resourceUrl": "converse.png",
      "defaultIndex": 0
    },
    {
      "id": 13,
      "thingName": "bull",
      "resourceUrl": "bull.png",
      "defaultIndex": 0
    },
    {
      "id": 14,
      "thingName": "pug",
      "resourceUrl": "pug.png",
      "defaultIndex": 0
    },
    {
      "id": 15,
      "thingName": "",
      "resourceUrl": "astronaut.png",
      "defaultIndex": 0
    },
    {
      "id": 16,
      "thingName": "",
      "resourceUrl": "banana.png",
      "defaultIndex": 0
    },
    {
      "id": 17,
      "thingName": "",
      "resourceUrl": "bullets.png",
      "defaultIndex": 0
    },
    {
      "id": 18,
      "thingName": "",
      "resourceUrl": "cactus.png",
      "defaultIndex": 0
    },
    {
      "id": 19,
      "thingName": "",
      "resourceUrl": "cat.png",
      "defaultIndex": 0
    },
    {
      "id": 20,
      "thingName": "",
      "resourceUrl": "chupachups.png",
      "defaultIndex": 0
    },
    {
      "id": 21,
      "thingName": "",
      "resourceUrl": "cloud.png",
      "defaultIndex": 0
    },
    {
      "id": 22,
      "thingName": "",
      "resourceUrl": "cupcake.png",
      "defaultIndex": 0
    },
    {
      "id": 23,
      "thingName": "",
      "resourceUrl": "drink.png",
      "defaultIndex": 0
    },
    {
      "id": 24,
      "thingName": "",
      "resourceUrl": "flamingo.png",
      "defaultIndex": 0
    },
    {
      "id": 25,
      "thingName": "",
      "resourceUrl": "gorilla.png",
      "defaultIndex": 0
    },
    {
      "id": 26,
      "thingName": "",
      "resourceUrl": "headphones.png",
      "defaultIndex": 0
    },
    {
      "id": 27,
      "thingName": "",
      "resourceUrl": "heart.png",
      "defaultIndex": 0
    },
    {
      "id": 28,
      "thingName": "",
      "resourceUrl": "ice.png",
      "defaultIndex": 0
    },
    {
      "id": 29,
      "thingName": "",
      "resourceUrl": "iswatching.png",
      "defaultIndex": 0
    },
    {
      "id": 30,
      "thingName": "",
      "resourceUrl": "jaguar.png",
      "defaultIndex": 0
    },
    {
      "id": 31,
      "thingName": "",
      "resourceUrl": "jumper.png",
      "defaultIndex": 0
    },
    {
      "id": 32,
      "thingName": "",
      "resourceUrl": "leaves.png",
      "defaultIndex": 0
    },
    {
      "id": 33,
      "thingName": "",
      "resourceUrl": "leg1.png",
      "defaultIndex": 0
    },
    {
      "id": 34,
      "thingName": "",
      "resourceUrl": "leg2.png",
      "defaultIndex": 0
    },
    {
      "id": 35,
      "thingName": "",
      "resourceUrl": "muscle.png",
      "defaultIndex": 0
    },
    {
      "id": 36,
      "thingName": "",
      "resourceUrl": "palmtree.png",
      "defaultIndex": 0
    },
    {
      "id": 37,
      "thingName": "",
      "resourceUrl": "partybaloons.png",
      "defaultIndex": 0
    },
    {
      "id": 38,
      "thingName": "",
      "resourceUrl": "raspberry.png",
      "defaultIndex": 0
    },
    {
      "id": 39,
      "thingName": "",
      "resourceUrl": "rocket.png",
      "defaultIndex": 0
    },
    {
      "id": 40,
      "thingName": "",
      "resourceUrl": "runner.png",
      "defaultIndex": 0
    },
    {
      "id": 41,
      "thingName": "",
      "resourceUrl": "stacks.png",
      "defaultIndex": 0
    },
    {
      "id": 42,
      "thingName": "",
      "resourceUrl": "sunflower.png",
      "defaultIndex": 0
    },
    {
      "id": 43,
      "thingName": "",
      "resourceUrl": "teddybear.png",
      "defaultIndex": 0
    },
    {
      "id": 44,
      "thingName": "",
      "resourceUrl": "weed.png",
      "defaultIndex": 0
    },
    {
      "id": 45,
      "thingName": "",
      "resourceUrl": "tomato.png",
      "defaultIndex": 0
    },
    {
      "id": 47,
      "thingName": "",
      "resourceUrl": "lion.png",
      "defaultIndex": 0
    },
    {
      "id": 48,
      "thingName": "",
      "resourceUrl": "aggressive.png",
      "defaultIndex": 0
    },
    {
      "id": 49,
      "thingName": "",
      "resourceUrl": "camera.png",
      "defaultIndex": 0
    },
    {
      "id": 50,
      "thingName": "",
      "resourceUrl": "casette.png",
      "defaultIndex": 0
    },
    {
      "id": 51,
      "thingName": "",
      "resourceUrl": "chimp.png",
      "defaultIndex": 0
    },
    {
      "id": 52,
      "thingName": "",
      "resourceUrl": "cola.png",
      "defaultIndex": 0
    },
    {
      "id": 53,
      "thingName": "",
      "resourceUrl": "harley.png",
      "defaultIndex": 0
    },
    {
      "id": 54,
      "thingName": "",
      "resourceUrl": "horse.png",
      "defaultIndex": 0
    },
    {
      "id": 55,
      "thingName": "",
      "resourceUrl": "mustang.png",
      "defaultIndex": 0
    },
    {
      "id": 56,
      "thingName": "",
      "resourceUrl": "okay.png",
      "defaultIndex": 0
    },
    {
      "id": 57,
      "thingName": "",
      "resourceUrl": "cookoo.png",
      "defaultIndex": 0
    },

    {
      "id": 59,
      "thingName": "",
      "resourceUrl": "rainbow.png",
      "defaultIndex": 0
    },
    {
      "id": 60,
      "thingName": "",
      "resourceUrl": "rolls.png",
      "defaultIndex": 0
    },
    {
      "id": 61,
      "thingName": "",
      "resourceUrl": "sad.png",
      "defaultIndex": 0
    },
    {
      "id": 62,
      "thingName": "",
      "resourceUrl": "solocups.png",
      "defaultIndex": 0
    },
    {
      "id": 63,
      "thingName": "",
      "resourceUrl": "timer.png",
      "defaultIndex": 0
    }
  ];
}



