import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import moment from 'moment';

const Changelog = () => {

  const trackPageView = (
    ReactGA.pageview('/changelog')
  );

  return (
    <div className="container mx-auto mb-32">
      <div className="flex flex-col items-center">
        <div className="max-w-lg mt-6 md:mt-24 px-4 md:px-0">
          <a href="/"><span role="img" aria-label="back">👈</span> go back</a>
          <h1
            className="text-3xl md:text-6xl font-semibold text-yellow-400 mb-2 md:mb-12 mt-12"
          >
            Changelog
          </h1>
          <p className="text-1xl font-light leading-normal text-gray-100">
            After a long wait, I&apos;m finally spending some time on Replace Cover. Here&apos;s what&apos;s new:
          </p>
          <div className="space-y-8 mt-8">
            <Update
              date={new Date('Aug 15 2020')}
              title="1.0.0 &mdash; It&apos;s baaaaack"
            >
              <ul className="list-disc pl-8 ml-0 space-y-2 text-lg font-light mt-3 leading-normal text-gray-100">
                <li>Back on{` `}
                  <a
                    href="https://github.com/facebook/create-react-app" 
                    title="Github page of Create React App"
                    alt="Link to Create React App"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="font-normal underline text-yellow-400"
                  >
                    Create React App
                  </a>. Ejecting wasn&apos;t worth the trouble.
                </li>
                <li>Added lazy loading for images</li>                
                <li>Improved accessibility for all interactive elements</li>
                <li>Moved entire codebase to Functional Components and React Hooks</li>
                <li>Added PropTypes</li>
                <li>Integrated{` `}
                  <a
                    href="https://tailwindcss.com/" 
                    title="Tailwindcss homepage"
                    alt="Link to Tailwindcss homepage"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="font-normal underline text-yellow-400"
                  >
                    tailwindcss
                  </a>
                  {` `}and will begin slooowly refactoring the styling to make use of it
                </li>
                <li>Started setting up{` `}
                  <a
                    href="https://graphcms.com/" 
                    title="GraphCMS homepage"
                    alt="Link to GraphCMS homepage"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="font-normal underline text-yellow-400"
                  >
                    GraphCMS
                  </a>. I plan on using it to maintain the Themes and Things used on RC as well as the random playlist titles
                </li>
              </ul>
            </Update>

            <Update
              date={new Date('March 12 2018')}
              title='0.9.999 &mdash; A loooong time ago, in a galaxy far far away, a Spotify playlist cover design app was born'
            />           
          </div>
        </div>
      </div>
      {trackPageView}
    </div>
  )
}

export default Changelog;


const Update = props => {
  const {
    title='',
    date = new Date(),
    children = [],
  } = props;

  const formattedDate = moment(date).format('MMMM Do, Y');
  return(
    <div className="">
      <span className="font-normal uppercase text-sm text-yellow-400">{formattedDate}</span>
      <h2 className="text-1xl md:text-2xl font-regular text-teal-200 leading-normal mt-1">{title}</h2>
      <div>
        {children}
      </div>
    </div>
  );
}

Update.propTypes = {
  title:PropTypes.string,
  date: PropTypes.instanceOf(Date),
  children: PropTypes.element,
}