import React from 'react';
// import domtoimage from 'dom-to-image';
// TODO: maybe try https://html2canvas.hertzen.com/
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga';
import html2canvas from 'html2canvas';
import { isMobile } from 'react-device-detect';

const Download = () => {
  const activeTheme = useSelector(state => state.activeTheme);
  const activeCopy = useSelector(state => state.activeCopy);
  const {
    playlistTitle,
  } = activeCopy;

  function saveCoverAsImage(){
    const canvasView = document.querySelector('#canvasPreview');
    const downloadName = `${playlistTitle}.jpeg`;
    let imageScale = 1;
    const imageWidth = canvasView.offsetWidth;;
    const imageHeight = canvasView.offsetHeight;

    if (isMobile) {
      imageScale = window.devicePixelRatio;	
    };
    document.body.classList.add('hide-scrollbar');
    html2canvas(canvasView, {
      width: imageWidth,
      height: imageHeight,
      scale: imageScale,
    })
      .then((canvas) => {
        const link = document.createElement('a');
        const imgData = canvas.toDataURL('image/jpeg',1)
          .replace('image/jpeg', 'image/octet-stream');
        link.download = downloadName;
        link.href = imgData;
        link.click();
        document.body.classList.remove('hide-scrollbar');
      });
    ReactGA.event({
      category: 'Covers',
      action: 'Downloaded A Cover',
      label: activeCopy.playlistTitle
    });
  }

  return(
    <div className="c-tools-section c-tools-Download">
      <div
        className="c-tools-section--action"
        onClick={()=>saveCoverAsImage()}
        onKeyPress={()=>saveCoverAsImage()}
        style={{background: activeTheme.backgroundColor }}
        tabIndex="0"
        role="button"
        aria-label="Download Cover"
      >
        <span 
          className="ic ic-download ic-download-dims"
          style={{
            color: activeTheme.contrastColor
          }}
        />
      </div>
    </div>   
  )
}

export default Download;