export default function () {
  return [
    {
      "id": 1,
      "themeName": "Blue + Yellow",
      "backgroundColor": "#0CF8E7",
      "textColor": "#FFFFFF",
      "contrastColor": "#B646FF"
    },
    {
      "id": 2,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#4087FC",
      "textColor": "#FFFFFF",
      "contrastColor": "#FFFF02"
    },
    {
      "id": 3,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#4CFC4F",
      "textColor": "#FFFFFF",
      "contrastColor": "#B646FF"
    },
    {
      "id": 4,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#FFDC39",
      "textColor": "#FFFFFF",
      "contrastColor": "#E539F8"
    },
    {
      "id": 5,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#873AFF",
      "textColor": "#FFFFFF",
      "contrastColor": "#E0FD35"
    },
    {
      "id": 6,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#47FD98",
      "textColor": "#FFFFFF",
      "contrastColor": "#F753CF"
    },
    {
      "id": 7,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#FC7044",
      "textColor": "#FFFFFF",
      "contrastColor": "#45D7FC"
    },
    {
      "id": 8,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#F84A02",
      "textColor": "#FFFFFF",
      "contrastColor": "#C9BD02"
    },
    {
      "id": 9,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#E1D0E0",
      "textColor": "#FFFFFF",
      "contrastColor": "#91CECF"
    },
    {
      "id": 10,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#52835B",
      "textColor": "#FFFFFF",
      "contrastColor": "#E4BA92"
    },
    {
      "id": 11,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#FFD37B",
      "textColor": "#FFFFFF",
      "contrastColor": "#80E4B6"
    },
    {
      "id": 12,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#050505",
      "textColor": "#FFFFFF",
      "contrastColor": "#FA0231"
    },
    {
      "id": 13,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#D4D4D4",
      "textColor": "#FFFFFF",
      "contrastColor": "#E484C9"
    },
    {
      "id": 14,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#AC4DFE",
      "textColor": "#FFFFFF",
      "contrastColor": "#45D7FC"
    },
    {
      "id": 15,
      "themeName": "Darker Blue + yellow",
      "backgroundColor": "#F8F8F8",
      "textColor": "#FFDC39",
      "contrastColor": "#00DACA"
    }
  ];
}