import React from 'react';
import Canvas from '../components/Canvas';
import Editor from '../components/Editor';
import Aside from '../components/Aside';

const Home = () => {
  return (
    <div className="v-home">
      <Editor />
      <Canvas />
      <Aside />
    </div>
  )
}

export default Home;