import React from 'react';
import { useDispatch, useSelector  } from 'react-redux'
import CoverPreview from "./CoverPreview";

import selectTheme from '../../actions/action_select_theme'

const Themes = () => {
  const themes = useSelector(state => state.themes);
  const activeTheme = useSelector(state => state.activeTheme);
  const activeThing = useSelector(state => state.activeThing);
  const dispatch = useDispatch();

  return (
    <section className="c-smallPreviewBoxGrid">
      {themes.map((theme) => (
        <CoverPreview
          key={theme.id}
          active={activeTheme.id === theme.id}
          onPress={() => dispatch(selectTheme(theme))}
          object={{...activeThing}}
          theme={theme}
          containerClass="c-smallPreviewBox"
        />
      ))}
    </section>
  );
}

export default Themes;