import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Typekit from 'react-typekit';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';

import Home from './views/Home';
import About from './views/About';
import Changelog from './views/Changelog';
import RemoveFocusWhenNotTab from './components/Helpers/RemoveFocusWhenNotTab';

import './scss/style.scss';

ReactGA.initialize('UA-116318007-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () =>{
  return (
    <BrowserRouter>
      <div className={`s-page ${  isMobile ? 'touch':'no-touch'}`}>
        <Route
          exact
          path='/'
          render={() => (
            <Home />
          )}
        />
        <Route
          exact
          path='/about'
          render={() => (
            <About />
          )}
        />
        <Route
          exact
          path='/changelog'
          render={() => (
            <Changelog />
          )}
        />
        <Typekit kitId="szh8xsx" />
        <RemoveFocusWhenNotTab />
      </div>
    </BrowserRouter>
  );
}

export default App;
